@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-color: #f8f8f8;
  user-select: none;
  /* overflow-x: hidden; */
}

:root {
  color: #000000;
  background-color: #f8f8f8;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

input,
textarea,
a:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  background-color: transparent;
}

select {
  appearance: none;
}

p {
  pointer-events: none;
}

#root {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

html {
  font-size: 90%;
}

@media (min-width: 30em) {
  html {
    font-size: 100%;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}

.property-card .delete-icon-wrapper {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.property-card:hover .delete-icon-wrapper {
  opacity: 1;
}

*::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar in Webkit browsers */
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none;
  /* Firefox-specific to hide scrollbar */
}